import React from 'react';

function ContactAddress() {
  return (
	  	<div className="col-xl-5 col-lg-6 col-md-8">
                <div className="contact-info-wrap">
                    <div className="contact-img">
                      <img src="img/contact/contact_img.png" alt="" />
                    </div>
                    <div className="footer-contact">
                  <div className="icon"><i className="fas fa-phone-alt" /></div>
                  <div className="content">
                    <h4 className="title"><a href="tel:0987654321">(904) 592-9166</a></h4>
                    <span>Call Us Now!</span>
                  </div>
                </div>
                <div className="footer-contact">
                  <div className="icon"><i className="fas fa-map-marker-alt" /></div>
                  <div className="content">
                    <h4 className="title"><a href="https://g.page/sunshineanimalclinic?share" target="_blank" rel="noopener noreferrer">4131 Southside Blvd, Suite 205</a></h4>
                    <span>Jacksonville, FL 32216</span>
                  </div>
                </div>
                <div className="footer-contact">
                  <div className="icon"><i className="fas fa-envelope" /></div>
                  <div className="content">
                    <h4 className="title"><a href="mailto: info@sunshineanimalclinic.com">Info@SunshineAnimalClinic.com</a></h4>
                    <span>Email Us!</span>
                  </div>
                </div>
                <div className="footer-contact">
                  <div className="icon"><i className="fas fa-clock" /></div>
                  <div className="content">
                    <h4 className="title">9:30 am - 7:00 pm</h4>
                    <span>Monday - Friday</span>
                  </div>
                </div>
                <div className="footer-contact">
                  <div className="content2">
                    <h4 className="title">9:00 am - 3:00 pm</h4>
                    <span>Saturday</span>
                  </div>
                </div>
                <div className="footer-contact">
                  <div className="content2">
                    <h4 className="title">Closed</h4>
                    <span>Sunday</span>
                  </div>
                </div>
                <div className="contact-social">
                    <ul>
                      <li><a href="https://www.facebook.com/sunshineanimalclinic" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="https://www.instagram.com/sunshineanimalclinic/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a></li>
                    </ul>
                </div>
              </div>
            </div>

  )
}

export default ContactAddress;
